@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.background-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    $grey-200,
    $grey-200 5px,
    $white 5px,
    $white 10px
  );
}
