@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-toolbar__content {
  padding-left: 15px;
  padding-right: 15px;

  .v-toolbar__title {
    margin-top: 2px;
  }
}
