// Fonts
$font-opensans: 'Open Sans';
$font-oswald: 'Oswald';

// Transitions
$global-transition-time: 0.33s;
$global-transition-time-fast: 0.25s;
$global-transition-time-slow: 0.5s;
$global-transition-animation: ease-in-out;
$global-transition: all $global-transition-time $global-transition-animation;

// Font family
$font-family-sans-serif: $font-opensans, monospace !default;
$font-family-monospace: Monaco, Consolas, 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

// Custom font family
$font-family-oswald: #{$font-oswald}, $font-family-sans-serif;

// Font-weight
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 600 !default;
$font-weight-bold: 700 !default;
